<template>
	<div class="cs-block cs-text-block" :class="`${styleClass}${hasBg}`">
		<div class="cs-block-base" :class="rootClasses">
			<div class="container">
				<h2 class="cs-title" v-if="data.title">
					<ColorSplit :data="data.title" />
				</h2>
				<h3 class="cs-sub-title" v-if="data.subTitle">{{ data.subTitle }}</h3>
				<span class="cs-text" v-if="data.text" v-html="data.text"></span>
				<div v-if="data.buttons && ((typeof data.buttons == 'object' && Object.keys(data.buttons).length) || (Array.isArray(data.buttons) && data.buttons.length))" class="block-buttons">
					<Buttons :data="data.buttons" :cs_style="data.style" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
	name: "Text",
	props: {
		data: {
			type: Object,
			default: () => {},
		},
	},
	components: {
		ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
		Buttons: () => import("../components/CSUtilsButtons.vue"),
	},
	computed: {
		...computed('TextBlock'),
		hasBg() {
			if (this.data.style == 'color') return ' has-background';

			return '';
		}
	},
	methods: {},
}
</script>

<style lang="scss" scoped>
@import "../styles/main.scss";
.cs-text-block {
	.cs-align-center * {
		text-align: center;
	}
	.cs-align-left * {
		text-align: left;
	}
	.cs-align-right * {
		text-align: right;
	}
	&.cs-style- {
		&color {
			@include cs-block-color;
			.cs-sub-title {
				color: $sub_title_color;
			}
			*,
			::v-deep * {
				color: white;
			}
		}

		&light {
			@include cs-block-light;
			.cs-sub-title {
				color: $sub_title_color_light;
			}
		}

		&dark {
			@include cs-block-dark;
			.cs-sub-title {
				color: $sub_title_color_dark;
			}
		}
	}
}
</style>
